import React from "react";
import styled from "styled-components";
import CombineMenu from "components/common/CombineMenu/CombineMenu";
import FixedFooter from "components/common/Footer/FixedFooter";
import "assets/css/termsnprivacy.scss";
import "components/SupportComponent/inquiryList/Inquiry.scss";
import { useRecoilValue } from "recoil";
import { isMobileAppState } from "atoms/userInfoAtom";

interface IContainer {
  children: React.ReactNode;
  background: string;
}

function CustomerWrap(props: IContainer) {
  const { children, background } = props;
  const isMobileApp = useRecoilValue(isMobileAppState);

  return (
    <div>
      {!isMobileApp && <CombineMenu />}
      <div
        id="wrapper"
        className="clearfix"
        style={{ marginTop: isMobileApp ? "0" : "60px" }}
      >
        <Content id="content" background={background}>
          <div className="row-w">
            {/* page contents들이 여기 쌓임 */}
            {children}
          </div>
        </Content>
      </div>
      <FixedFooter />
    </div>
  );
}

export default CustomerWrap;

const Content = styled.div<{ background: string }>`
  padding: 3rem 0 !important;
  min-height: calc(100vh - 110px) !important; // 55 - 175
  background: ${(props) => props.background || "#242526"} !important;
  box-sizing: border-box;

  @media all and (max-width: 1024px) {
    min-height: calc(100vh - 297px); // 50 - 247
  }
`;
