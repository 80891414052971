import { useClickNickname } from "hooks/useClickNickname";
import styled from "styled-components";

export default function WriterProfileImg({
  userId,
  img
}: {
  userId: string;
  img: string;
}) {
  const firstLetter = userId.split("")[0].toLowerCase();
  const onClickNickname = useClickNickname();

  const colorPicker = () => {
    switch (true) {
      case "abkluv56".includes(firstLetter):
        return "#E86E90";
      case "cdmnwx78".includes(firstLetter):
        return "#D08850";
      case "efopyz9".includes(firstLetter):
        return "#9E69B5";
      case "ghqr120".includes(firstLetter):
        return "#59B4CB";
      default:
        return "#4FBB74";
    }
  };

  if (img === null || img.length === 0) {
    return (
      <Img className="user-img" color={colorPicker()}>
        <p>{firstLetter}</p>
      </Img>
    );
  }

  return <img src={img} className="user-img" />;
}

const Img = styled.div<{ color: string }>`
  background: ${(prop) => prop.color};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: var(--gray-100);
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
`;
