import React from "react";
import './Fall.scss';
export default function Fall() {
  return (
    <div id={`bg`} className={`theme fall`}>
      {/*<img id={`leaf`} src="//file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/fall/leaf_animation.svg" alt=""/>*/}
  
      <object id="fall_leaf" type="image/svg+xml" data="https://file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/fall/leaf_animation.svg">
        Your browser does not support SVG
      </object>
  
      <object id="fall_front_leaf" type="image/svg+xml" data="https://file.qijisoft.com/Valofe_file/web/vfun/images/sub/membership/fall/front-flowers-animation.svg">
        Your browser does not support SVG
      </object>
      
    </div>
  )
}