import { Link } from "react-router-dom";
import PopUpWrap from "components/common/PopUpWrap";
import { useRecoilState, useRecoilValue } from "recoil";
import { popUpState } from "atoms/uiAtom";
import { t } from "i18next";
import { isLoginStateSelector } from "atoms/userInfoAtom";
import { LauncherLogin } from "utils/CommonFunc";

function ShopPopUp() {
  const [pointPopUp, setPointPopUp] = useRecoilState(popUpState("main"));
  const isLoginState = useRecoilValue(isLoginStateSelector);

  const onClickClose = () => {
    setPointPopUp(false);
  };

  return (
    <PopUpWrap close={onClickClose} popUp={pointPopUp}>
      <img
        src="https://file.qijisoft.com/Valofe_file/web/vfun/images/common/icon-alert.png"
        alt="alert icon"
      />
      <h6 className="alert-text text1">{t("shop_point_error")}</h6>
      <div className="btn-wrap clearfix" id="alertBtn">
        <Link to="#" className="btn-alert-line" onClick={onClickClose}>
          {t("close")}
        </Link>
        <Link
          to={
            isLoginState.nonLoginLauncher
              ? window.location.href
              : "/stamp/my_stamp"
          }
          className="btn-alert-line"
          onClick={() => LauncherLogin(isLoginState.nonLoginLauncher)}
        >
          {t("stamp_menu3")}
        </Link>
      </div>
    </PopUpWrap>
  );
}

export default ShopPopUp;
