import { t } from "i18next";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { popUpState } from "atoms/uiAtom";
import { SIGN_IN_URL, VFUN_IMAGE_DNS } from "utils/config/Config";
import PopUpWrap from "components/common/PopUpWrap";
import { isLoginStateSelector } from "atoms/userInfoAtom";

function LoginPopUp() {
  const [loginPopUp, setLoginPopUp] = useRecoilState(popUpState("login"));
  const isLoginState = useRecoilValue(isLoginStateSelector);

  const onClickClose = () => {
    setLoginPopUp(false);
  };

  const onClickSignIn = () => {
    // localStorage.setItem("prevPath", location.pathname + location.search);
    window.app.vfunRequestLogin();
    if (isLoginState.isLauncher) {
      setLoginPopUp(false);
    }
  };

  return (
    <PopUpWrap close={onClickClose} popUp={loginPopUp}>
      <img
        src={`${VFUN_IMAGE_DNS}/web/vfun/images/common/icon-alert.png`}
        alt="alert icon"
      />
      <h6 className="alert-text mt10 text1">
        {t("log_in_is_required_for_this_service")}
      </h6>
      <div className="btn-wrap clearfix" id="alertBtn">
        <Link
          to={window.location.href}
          className="btn-alert-line"
          onClick={onClickClose}
        >
          {t("cancel")}
        </Link>
        <Link
          to={
            isLoginState.nonLoginLauncher
              ? window.location.href
              : SIGN_IN_URL("vfun")
          }
          className="btn-alert"
          onClick={onClickSignIn}
        >
          {t("signin")}
        </Link>
      </div>
    </PopUpWrap>
  );
}

export default LoginPopUp;
