import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import "./Language.scss";
import { useCookies } from "react-cookie";
import { VFUN_IMAGE_DNS } from "utils/config/Config";
import { dropBoxToggleState } from "../recoil/recoil";
import { langCodeState, langListState, langState } from "atoms/contentsAtom";
import { isLauncherState } from "atoms/userInfoAtom";

function Language() {
  const { t, i18n } = useTranslation();
  const [dropBoxToggle, setDropBoxToggle] = useRecoilState(dropBoxToggleState);
  const [lang, setLang] = useRecoilState(langState);
  const [langCode, setLangCode] = useRecoilState(langCodeState);
  const [cookies, setCookie] = useCookies();
  const langList = useRecoilValue(langListState);
  const isLauncher = useRecoilValue(isLauncherState);
  const vfunLauncherLang = cookies["vfunLauncherLocale"];

  const hileLang = () => {
    if (dropBoxToggle.bell || dropBoxToggle.profile) {
      setDropBoxToggle({ profile: false, bell: false, lang: true });
    } else {
      setDropBoxToggle((prev) => ({ ...prev, lang: !prev.lang }));
    }
  };

  const changeLanguage = (language: string, text: string) => {
    i18n.changeLanguage(language);
    setLang(text);
    setLangCode(language);
  };

  useEffect(() => {
    if (isLauncher) {
      window.app.vfunSiteLangChange(langCode, vfunLauncherLang);
    }
  }, [dropBoxToggle.lang, langCode, vfunLauncherLang]);

  useEffect(() => {
    if (langList.length !== 0) {
      setCookie("locale", langCode, { path: "/", domain: ".valofe.com" });
    }
    setDropBoxToggle((prev) => ({ ...prev, lang: false }));
  }, [langCode]);

  const activationLang = (lang: string) => {
    if (lang === langCode) {
      return "on";
    }
  };

  return (
    <div className="cbm-sel-lang" onClick={hileLang}>
      <div className={`sel-lang ${dropBoxToggle.lang ? "on" : ""}`}>
        {t(lang)}
        <img
          src={`${VFUN_IMAGE_DNS}/web/vfun/images/cbm/cbm-sel-arrow${
            dropBoxToggle.lang ? "-on" : ""
          }.png`}
        />
      </div>

      <ul
        className="box"
        style={{ display: dropBoxToggle.lang ? "block" : "none" }}
      >
        <div className="shadow" />
        {langList.map((i, idx) => (
          <li key={idx}>
            <div
              className={activationLang(i.lang)}
              onClick={() => changeLanguage(i.lang, i.lang_code)}
            >
              {t(i.lang_code)}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default React.memo(Language);
