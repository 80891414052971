import { langCodeState } from "atoms/contentsAtom";
import { Helmet } from "react-helmet-async";
import { useRecoilValue } from "recoil";

function VfunHelmet(props: { title: string }) {
  const langCode = useRecoilValue(langCodeState);

  return (
    <Helmet htmlAttributes={{ lang: `${langCode}` }}>
      <title>VFUN - {props.title}</title>
    </Helmet>
  );
}

export default VfunHelmet;
