import {
  isLogInState,
  userIdState,
  userProfileImgState
} from "atoms/userInfoAtom";
import { useRecoilValue } from "recoil";

import styled from "styled-components";
import { VFUN_IMAGE_DNS } from "utils/config/Config";

export default function UserProfileImg() {
  const isLogin = useRecoilValue(isLogInState);
  const userProfileImg = useRecoilValue(userProfileImgState);
  const userId = useRecoilValue(userIdState);
  const firstLetter = userId.split("")[0].toLowerCase();

  const colorPicker = () => {
    switch (true) {
      case "abkluv56".includes(firstLetter):
        return "#E86E90";
      case "cdmnwx78".includes(firstLetter):
        return "#D08850";
      case "efopyz9".includes(firstLetter):
        return "#9E69B5";
      case "ghqr120".includes(firstLetter):
        return "#59B4CB";
      default:
        return "#4FBB74";
    }
  };

  if (!isLogin) {
    return (
      <img
        src={`${VFUN_IMAGE_DNS}/web/vfun-ko/images/common/img-user-default-min.png`}
        className="user-img"
      />
    );
  }

  if (userProfileImg.length === 0) {
    return (
      <Img className="user-img" color={colorPicker()}>
        <p>{firstLetter}</p>
      </Img>
    );
  }

  return <img src={userProfileImg} className="user-img" />;
}

const Img = styled.div<{ color: string }>`
  background: ${(prop) => prop.color};
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--gray-100);
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
`;
