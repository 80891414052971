import { slide as Menu } from "react-burger-menu";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { t } from "i18next";
import { VFUN_IMAGE_DNS } from "utils/config/Config";
import { useEffect, useState } from "react";
import { IAllGameData, getAllGames } from "api/api";
import Language from "./Language";
import sideMenuList from "components/common/CombineMenu/Data/sideMenuList.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMagnifyingGlass,
  faX
} from "@fortawesome/free-solid-svg-icons";
import { langCodeState } from "atoms/contentsAtom";
import { isLogInState } from "atoms/userInfoAtom";

function GameList({ isOpen, value }: { isOpen: boolean; value: string }) {
  const langCode = useRecoilValue(langCodeState);
  const [newData, setNewData] = useState<IAllGameData[]>([]);
  const [filterData, setFilterData] = useState<IAllGameData[]>([]);

  const { isLoading, data } = useQuery(
    ["allgames", langCode],
    () => getAllGames(langCode),
    { enabled: isOpen }
  );

  useEffect(() => {
    if (!isLoading && data?.result === 1) {
      setNewData(data.data);
      setFilterData(data.data);
    }
  }, [isLoading, value]);

  useEffect(() => {
    if (value === "") {
      setFilterData(newData);
    } else {
      let result = newData.filter(
        (i) =>
          i.game_title !== null &&
          i.game_title.toLowerCase().includes(value.toLowerCase())
      );
      setFilterData(result);
    }
  }, [value, newData]);

  if (isLoading) return <div>Loading</div>;

  const dataByGenre = (data: IAllGameData[]) => {
    const groupedData: { [key: string]: IAllGameData[] } = {};
    if (isOpen) {
      data.forEach((game: IAllGameData) => {
        if (groupedData[game.game_genre]) {
          groupedData[game.game_genre].push(game);
        } else {
          groupedData[game.game_genre] = [game];
        }

        return game;
      });
    }

    return groupedData;
  };

  const groupedData = dataByGenre(filterData);

  return (
    <div>
      {Object.keys(groupedData).map((i) => (
        <div key={i}>
          <p className="sub-title">{i}</p>
          <ul>
            {groupedData[i].map((j) => (
              <li key={j.idx}>
                <Link to={`/library?service_code=${j.service_code}`}>
                  {j.game_title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

function SideMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [inputToggle, serInputToggle] = useState(false);
  const [value, setValue] = useState("");
  const isLogin = useRecoilValue(isLogInState);

  const width768 = useMediaQuery({
    query: "screen and (max-width: 768px)"
  });

  const shouldRenderMenu = (menuCode: string) => {
    if (isLogin) {
      return menuCode !== "membership";
    } else {
      return menuCode !== "stamp";
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "auto";
  }, [isOpen]);

  return (
    <Menu
      pageWrapId={"page-wrap"}
      outerContainerId={"combineMenu"}
      onStateChange={(res) => setIsOpen(res.isOpen)}
      isOpen={isOpen}
      customBurgerIcon={<FontAwesomeIcon icon={faBars} color={"#fff"} />}
      customCrossIcon={<FontAwesomeIcon icon={faX} color={"#fff"} />}
    >
      {width768 && <Language />}
      <div className="short-cut">
        <p className="title">{t("short_cut")}</p>
        <ul>
          <li>
            <Link to={process.env.REACT_APP_PLATFORM_DNS as string}>
              <img
                src={`${VFUN_IMAGE_DNS}/web/vfun/images/logo/vfun-logo.png`}
                alt="vfun logo"
              />
              <p>VFUN</p>
            </Link>
          </li>
          <li>
            <Link to={process.env.REACT_APP_LOUNGE_DNS as string}>
              <img
                src={`${VFUN_IMAGE_DNS}/web/vfun/images/logo/lounge-logo.png`}
                alt="lounge logo"
              />
              <p>
                VFUN
                <br />
                Lounge
              </p>
            </Link>
          </li>
          <li>
            <Link to={process.env.REACT_APP_GROUND_DNS as string}>
              <img
                src={`${VFUN_IMAGE_DNS}/web/vfun/images/logo/ground-logo.png`}
                alt="ground logo"
              />
              <p>
                VFUN
                <br />
                Ground
              </p>
            </Link>
          </li>
        </ul>
      </div>

      <div
        className="all-game-list y-scroll"
        onScroll={(e: React.UIEvent<HTMLElement>) => {
          if (e.currentTarget.scrollTop !== 0) serInputToggle(false);
        }}
      >
        <div className="title">
          ALL GAMES
          <div
            className="input-area"
            style={{
              width: inputToggle ? "100%" : "32px",
              borderRadius: inputToggle ? "8px" : "15px"
            }}
          >
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              onClick={() => serInputToggle((prev) => !prev)}
            />
            {inputToggle && (
              <input
                type="text"
                placeholder={t("search_for_games")}
                autoFocus={inputToggle}
                onBlur={() => serInputToggle(false)}
                onChange={(e) => setValue(e.target.value)}
                value={value}
              />
            )}
          </div>
        </div>

        <GameList isOpen={isOpen} value={value} />
      </div>

      <div>
        {sideMenuList.data.map((i, idx) => {
          if (!shouldRenderMenu(i.menu_code)) {
            return null;
          }

          return (
            <div
              className={sideMenuList.data.length - 1 === idx ? "" : "pb20"}
              key={i.menu_code}
            >
              <p className="sub-title">{i.menu_code}</p>
              <ul>
                {i.sub_menus.map((j) => (
                  <li key={j.menu_code}>
                    <Link
                      to={`${process.env.REACT_APP_PLATFORM_DNS}${j.target_url}`}
                    >
                      {t(j.menu_code)}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </Menu>
  );
}

export default SideMenu;
