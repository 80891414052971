import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useCookies, withCookies } from "react-cookie";
import "languages/i18n";
import md5 from "md5";
import ReactGA from "react-ga4";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import "App.scss";
import {
  checkPosition,
  naverAuthorizePopUp,
  redirectURL,
  Service_GL
} from "utils/CommonFunc";
import {
  connectSiteState,
  emailAuthStatus,
  headersState,
  isLauncherState,
  isPrivacyAgreeState,
  juminState,
  isLogInState,
  naverAuthorizeUrl,
  ssoinfoState,
  userBirthState,
  userCountryState,
  userEmailState,
  userIdState,
  userNicknameState,
  userProfileImgState,
  userProfileKeyState,
  isLoginStateSelector,
  isMobileAppState
} from "atoms/userInfoAtom";
import { initGA } from "utils/tracking";
import TagManager from "react-gtm-module";
import {
  GET_MEMBERINFO,
  ILang,
  IMember,
  getLanguage,
  naverMemberStatus
} from "api/api";
import { VERIFY_KEY } from "utils/config/Config";
import WrapLoading from "components/loading/WrapLoading";
import PageRoutes from "routers/PageRoutes";
import ScrollTop from "utils/ScrollTop";
import ResetInfo from "utils/ResetInfo";
import { langCodeState, langListState, langState } from "atoms/contentsAtom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import axios from "axios";
import airbridge from "airbridge-web-sdk-loader";
import { getAirbridgeSendEvent } from "api/libraryApi";
import { useLogout } from "hooks/useLogout";

function App() {
  const setIsLogin = useSetRecoilState(isLogInState);
  const setUserID = useSetRecoilState(userIdState);
  const setSSOInfo = useSetRecoilState(ssoinfoState);
  const setJuminInfo = useSetRecoilState(juminState);
  const setUserBirth = useSetRecoilState(userBirthState);
  const setUserEmail = useSetRecoilState(userEmailState);
  const setConnectSite = useSetRecoilState(connectSiteState);
  const setIsPrivacyAgree = useSetRecoilState(isPrivacyAgreeState);
  const setNaverAuthorize = useSetRecoilState(naverAuthorizeUrl);
  const setLangList = useSetRecoilState(langListState);
  const setLang = useSetRecoilState(langState);
  const setIsLauncher = useSetRecoilState(isLauncherState);
  const setUserNickName = useSetRecoilState(userNicknameState);
  const setUserCountry = useSetRecoilState(userCountryState);
  const setUserProfileKey = useSetRecoilState(userProfileKeyState);
  const setEmailAuth = useSetRecoilState(emailAuthStatus);
  const setUserProfileImg = useSetRecoilState(userProfileImgState);
  const setIsMobileApp = useSetRecoilState(isMobileAppState);

  const deviceType = window.navigator.userAgent.split("::")[1];
  const isLoginState = useRecoilValue(isLoginStateSelector);
  const [isLoading, setIsLoading] = useState(true);
  const [langCode, setLangCode] = useRecoilState(langCodeState);
  const [cookies, setCookie, removeCookie] = useCookies();
  const langList = useRecoilValue(langListState);
  const { i18n } = useTranslation();
  const headers = useRecoilValue(headersState);
  const logout = useLogout();

  axios.defaults.withCredentials = true;

  // 언어
  const { isLoading: langLoading, data: langData } = useQuery<ILang>(
    "lang",
    getLanguage
  );

  useEffect(() => {
    if (langData?.result === 1) {
      setLangList(langData.data);
      setUserCountry(langData.country.toUpperCase());
    }
  }, [langLoading]);

  // 언어 쿠키 참조
  useEffect(() => {
    if (Service_GL) {
      // 글로벌 일 경우
      if (cookies["locale"] !== undefined || cookies["locale"] !== null) {
        for (let i = 0; i < langList.length; i++) {
          if (langList[i].lang === cookies["locale"]) {
            setCookie("locale", cookies["locale"], {
              path: "/",
              domain: ".valofe.com"
            });
            setLang(langList[i].lang_code);
            setLangCode(cookies["locale"]);
            i18n.changeLanguage(cookies["locale"]);
          }
        }
      } else {
        setCookie("locale", "en", { path: "/", domain: ".valofe.com" });
        setLang("English");
        setLangCode("en");
        i18n.changeLanguage("en");
      }
    } else {
      setCookie("locale", "ko", { path: "/", domain: ".valofe.com" });
      setLang("한국어");
      setLangCode("ko");
      i18n.changeLanguage("ko");
    }
  }, [langList]);

  // 런처 여부
  useEffect(() => {
    if (deviceType === "vfun-launcher") {
      setIsLauncher(true);
    } else if (deviceType === "vfun-app") {
      setIsMobileApp(true);
    }
  }, [deviceType]);

  // 런처 로그인, 아웃
  useEffect(() => {
    if (isLoginState.isLauncher) {
      window.app.vfunSitInitFinish();
    }
    window.vfunLogin = function () {
      alert("Please close the VFUN launcher and restart it for an update.");
      return;
    };
    window.vfunLogout = function () {
      logout();
    };
    window.vfunOuterLogin = function (
      refreshToken: string,
      service_code: string
    ) {
      if (service_code === "vfun") {
        window.location.reload();
      } else {
        redirectURL(
          `/library?service_code=${service_code}&section=home`,
          "_self",
          isLoginState.isLauncher
        );
      }
    };
    window.vfunGameDetailPage = function (service_code: string) {
      redirectURL(
        `/library?service_code=${service_code}&section=home`,
        "_self",
        isLoginState.isLauncher
      );
    };

    window.vfunLangChange = function (
      lang: string,
      lang_code: string,
      vfunLauncherLang: string
    ) {
      setCookie("locale", lang, { path: "/", domain: ".valofe.com" });
      setCookie("vfunLauncherLocale", vfunLauncherLang, {
        path: "/",
        domain: ".valofe.com"
      });
      i18n.changeLanguage(lang);
      setLang(lang_code);
      setLangCode(lang);
    };
  }, []);

  // google GA
  useEffect(() => {
    if (
      window.location.hostname.includes(".valofe.com") ||
      window.location.hostname.includes("localhost")
    ) {
      valofe_process();
      setConnectSite("vfun");
      //2023. 7월 1일부터 google GA가 UA 속성 지원하지 않음
      initGA(Service_GL ? "G-G9XDFFQ5PJ" : "G-1T67BP0JE8");
      ReactGA.send("pageview"); // 페이지 뷰가 다른 이벤트 추척보다 선행되어야 함
      ReactGA.event({
        category: "User",
        action: "Created an Account", //계정생성 추적
        label: Service_GL ? "VFUN Lounge 회원 가입" : "VFUN KR 회원 가입" // + 서비스 코드가 들어가면 더 좋겠습니다.
      });

      const tagManagerArgs = {
        gtmId: "GTM-W9ZFSCR"
      };
      TagManager.initialize(tagManagerArgs);

      // 서비스 코드 쿠키
      if (
        cookies["service_code"] === undefined ||
        cookies["service_code"] === null
      ) {
        if (Service_GL) {
          setCookie("service_code", "vfun", {
            path: "/",
            domain: ".valofe.com"
          });
        } else {
          setCookie("service_code", "vfun-ko", {
            path: "/",
            domain: ".valofe.com"
          });
        }
      }
    } else {
      naver_process();
      window.addEventListener(
        "message",
        (e) => e.data.message === "naver_agree" && window.location.reload()
      );
      setConnectSite("naver");
    }
  }, []);

  // uuid
  useEffect(() => {
    if (window.location.href.includes("uuid")) {
      const uuid = window.location.search.replace("?uuid=", "");

      setCookie("_vl", btoa(uuid));
    }
  }, []);

  // airbridge
  useEffect(() => {
    window.vFunAirbridge_Log = function (
      category: string,
      serviceCode: string
    ) {
      airbridge.fetchResource(function (res) {
        const client_id = res.browser.clientID;
        const user_agent = encodeURIComponent(res.browser.userAgent);
        const short_id = res.attributionData.shortID;
        const channel = res.attributionData.trackingData.channel;

        getAirbridgeSendEvent(
          serviceCode,
          langCode,
          client_id,
          user_agent,
          short_id,
          channel,
          category,
          headers
        );
      });
    };
  }, []);

  const valofe_process = async () => {
    if (Service_GL) {
      // 글로벌
      setIsPrivacyAgree(true);
      let cookies_ssoinfo = "";
      let cookies_userid = cookies["userid"];
      let cookies_birthday = cookies["birthday"];
      let expired_at = cookies[process.env.REACT_APP_COOKIES_EXPIRED as string];

      if (expired_at !== undefined) {
        try {
          let res: any = await GET_MEMBERINFO({
            Ssoinfo: encodeURIComponent(cookies_ssoinfo),
            Userid: cookies_userid,
            Userbirth: cookies_birthday
          });
          if (res.result === 1) {
            const userData: IMember = res;
            setUserID(userData.data.user_id);
            setSSOInfo(userData.sso_info);
            setUserBirth(userData.data.user_birthday);
            setUserEmail(userData.data.email);
            setUserNickName(userData.data.nickName || "");
            setUserProfileKey(userData.data.user_profile_key);
            setUserProfileImg(userData.data.profile_image_url || "");
            setIsLogin(true);
            setEmailAuth(userData.email_auth_status);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    } else {
      // 한국
      setIsPrivacyAgree(true);
      let cookies_goonzu = cookies["goonzu"];
      if (cookies_goonzu === undefined) {
        setIsLogin(false);
      } else {
        let cookies_info = cookies_goonzu.split("&");
        if (cookies_info.length > 0) {
          let param: any = [];
          for (let i = 0; i < cookies_info.length; i++) {
            let key = cookies_info[i].split("=")[0];
            let value = cookies_info[i].split("=")[1];
            param[key] = value;
          }
          let jumin = cookies["jumin"];
          let ssoinfo =
            cookies[process.env.REACT_APP_SSO_INFO_COOKIE_NAME as string];
          let user_id = decodeURI(param["userid"]);
          let cookies_string = user_id + jumin + VERIFY_KEY;
          if (md5(cookies_string) === ssoinfo) {
            setUserID(user_id);
            setSSOInfo(ssoinfo);
            setJuminInfo(jumin);
            setIsLogin(true);
          } else {
            setIsLogin(false);
          }
        } else {
          setIsLogin(false);
        }
      }
      setIsLoading(false);
    }
  };

  const naver_process = async () => {
    const serviceCode = window.location.pathname.split("/")[2];
    const gdp_login = cookies["GDP_LOGIN"];

    if (gdp_login === undefined) {
      setIsLogin(false);
      setIsPrivacyAgree(false);
    } else {
      try {
        let res: any = await naverMemberStatus(
          {
            loginkey: gdp_login
          },
          serviceCode
        );

        if (res.error_code !== "1000") {
          checkPosition();
          naverAuthorizePopUp(res.authorize);

          setIsPrivacyAgree(false);
          setNaverAuthorize(res.authorize);
        } else {
          let ssoinfo = res.access_token;
          let user_id = res.userid;
          setSSOInfo(ssoinfo);
          setUserID(user_id);
          setIsPrivacyAgree(true);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
      setIsLogin(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoginState.isLogIn) {
      window.localStorage.removeItem("prevPath");
    }
  }, [isLoginState.isLogIn]);

  return (
    <Router>
      <ScrollTop />
      <ResetInfo />
      {isLoading ? <WrapLoading /> : <PageRoutes />}
    </Router>
  );
}

export default withCookies(App);
