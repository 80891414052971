import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation, withTranslation } from "react-i18next";
import { Service_GL, billingURL, redirectURL } from "utils/CommonFunc";
import { BILLING_CHARGE_URL } from "utils/config/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { headersState, isLauncherState } from "atoms/userInfoAtom";
import { dropBoxToggleState } from "../recoil/recoil";
import { getGcoin } from "../api/api";
import { CMservice_code } from "../utils/serviceCode";

function Wallet() {
  const { t } = useTranslation();
  const headers = useRecoilValue(headersState);
  const [gcoin, setGcoin] = useState(0);
  const isLauncher = useRecoilValue(isLauncherState);
  const dropBoxToggle = useRecoilValue(dropBoxToggleState);

  const { isLoading, data } = useQuery(
    ["gcoin"],
    () => getGcoin(CMservice_code || "", headers),
    { enabled: !!dropBoxToggle.profile }
  );

  useEffect(() => {
    if (data?.result !== undefined && data.result === 1) {
      if (Service_GL) {
        setGcoin(data.data.bonus_cash + data.data.real_cash);
      } else {
        if (data.data.return_code[0] === "0000") {
          setGcoin(data.data.bonus_cash + data.data.real_cash);
        }
      }
    } else {
      setGcoin(0);
    }
  }, [data]);

  return (
    <ul>
      <li className="gray-box row-flex">
        <p>{t("gcoins")}</p>
        <div>
          {!isLoading ? (
            <p>{gcoin.toLocaleString()}</p>
          ) : (
            <FontAwesomeIcon icon={faSpinner} pulse />
          )}
          <Link
            to={window.location.href}
            onClick={() =>
              billingURL(
                BILLING_CHARGE_URL(CMservice_code || "vfun", 1),
                "_self",
                isLauncher
              )
            }
            className="purple btn"
          >
            {t("charge")}
          </Link>
        </div>
      </li>
      <li className="gray-box row-flex">
        <p>Point</p>
        <div>
          {!isLoading ? (
            <p>{data?.result === 1 ? data.data.point.toLocaleString() : 0}</p>
          ) : (
            <FontAwesomeIcon icon={faSpinner} pulse />
          )}
          <Link
            to="#"
            className="purple btn"
            onClick={() =>
              redirectURL(
                `${process.env.REACT_APP_PLATFORM_DNS}/stamp/my_stamp`,
                "_self",
                isLauncher
              )
            }
          >
            GET
          </Link>
        </div>
      </li>
    </ul>
  );
}

export default React.memo(withTranslation()(Wallet));
