import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom";
import { dateFormType2, deletetag, redirectURL } from "utils/CommonFunc";
import parse from "html-react-parser";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecoilValue } from "recoil";
import { headersState, isLauncherState } from "atoms/userInfoAtom";
import { useInfiniteScrollQuery } from "../api/notificationApi";
import { getTargetUrl } from "../api/api";
import WriterProfileImg from "components/common/WriterProfileImg";
import { t } from "i18next";

type pageData = {
  created_at: string;
  is_read: 0 | 1;
  noti_message: string;
  service_code: string;
  title: string;
  thumbnail: string;
  idx: number;
  user_id: string;
};

export default function NotificationList({ tabIdx }: { tabIdx: number }) {
  const headers = useRecoilValue(headersState);
  const isLauncher = useRecoilValue(isLauncherState);

  const { getBoard, getNextPage, getBoardIsSuccess, getNextPageIsPossible } =
    useInfiniteScrollQuery(tabIdx);

  const [ref, isView] = useInView();

  useEffect(() => {
    if (isView && getNextPageIsPossible) {
      getNextPage();
    }
  }, [isView, getBoard]);

  const targetUrl = (idx: number) => {
    getTargetUrl(idx, headers, tabIdx).then((res) => {
      if (res.data.result === 1) {
        redirectURL(res.data.data.url, "_self", isLauncher);
      } else return;
    });
  };

  if (!getBoardIsSuccess) {
    return (
      <ContentLoader
        speed={2} // 속도
        viewBox="0 0 500 470"
        backgroundColor="var(--gray-600)" // 배경색
        foregroundColor="var(--gray-900)" // 요소 색
      >
        {[...Array(3)].map((i, idx) => (
          <rect
            x="0"
            y={idx === 0 ? "0" : `${160 * idx}px`}
            rx="10"
            ry="10"
            width="100%"
            height="150px"
            key={idx}
          />
        ))}
      </ContentLoader>
    );
  } else {
    return getBoard?.pages[0].result === 1 ? (
      <ul className="notice-list y-scroll">
        {getBoard?.pages.map((pageData) =>
          pageData.boardpage.map((i: pageData) => (
            <li className="gray-box" key={i.idx}>
              <Link
                to="#"
                className={i.is_read === 1 ? "visit" : ""}
                onClick={() => targetUrl(i.idx)}
              >
                <div>
                  <WriterProfileImg userId={i.user_id} img={i.thumbnail} />
                  <div className="cont-wrap">
                    {i.service_code === "" ||
                    i.service_code === "none" ? null : (
                      <p>{i.service_code}</p>
                    )}
                    <p className="cont">
                      {parse(i.noti_message)}
                      {i.title !== null
                        ? ` : ${deletetag(i.title, [""])}`
                        : null}
                    </p>
                  </div>
                </div>

                <p>{dateFormType2(i.created_at)}</p>
              </Link>
            </li>
          ))
        )}
        {getNextPageIsPossible && getBoard?.pages[0].result === 1 ? (
          <li ref={ref} className="loading">
            <FontAwesomeIcon icon={faSpinner} spinPulse />
            Loading
          </li>
        ) : null}
      </ul>
    ) : (
      <div className="none">{t("no_notification")}</div>
    );
  }
}
