import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  eventPopUpDataState,
  isStepState,
  menuOpenState,
  popUpNewsState,
  popUpState,
  preRegisterPopUpState,
  selectMenuState,
  smsPopUpState,
  snsQrPopUpState,
  snsSharePopUpState
} from "atoms/uiAtom";
import {
  inputValueState,
  isValidState,
  validTextState
} from "atoms/userInfoAtom";
import { Service_GL } from "./CommonFunc";
import airbridge from "airbridge-web-sdk-loader";
import { useRefreshToken } from "hooks/useRefreshToken";

function ResetInfo() {
  const { pathname, search } = useLocation();
  const setIsStep = useSetRecoilState(isStepState);
  const setInputValue = useSetRecoilState(inputValueState);
  const [isValid, setIsValid] = useRecoilState(isValidState);
  const setValidText = useSetRecoilState(validTextState);
  const setOpenSideMenu = useSetRecoilState(menuOpenState);
  const setLoginPopUp = useSetRecoilState(popUpState("login"));
  const setSuccessSignUpPopUp = useSetRecoilState(popUpState("signup"));
  const setHistoryPopUp = useSetRecoilState(popUpState("history"));
  const setGetPointPopUp = useSetRecoilState(popUpState("getPoint"));
  const setGetPointSuccessPopUp = useSetRecoilState(
    popUpState("getPointSuccess")
  );
  const setGetPointFailPopUp = useSetRecoilState(popUpState("getPointFail"));
  const [popUpNews, setPopUpNews] = useRecoilState(popUpNewsState);
  const [registerPopUp, setRegisterPopUp] = useRecoilState(
    preRegisterPopUpState
  );
  const [smsPopUp, setSmsPopUp] = useRecoilState(smsPopUpState);
  const setSelectMenu = useSetRecoilState(selectMenuState);
  const setEventPopUpData = useSetRecoilState(eventPopUpDataState);
  const [snsSharePopUp, setSnsSharePopUp] = useRecoilState(snsSharePopUpState);
  const [snsQrPop, setSnsQrPop] = useRecoilState(snsQrPopUpState);
  const refreshToken = useRefreshToken();

  useEffect(() => {
    setIsStep({ one: true, two: false, three: false });
    setInputValue({
      birth: "",
      email: "",
      firstName: "",
      id: "",
      lastName: "",
      password: "",
      passwordConfirm: "",
      nickName: "",
      subject: "",
      description: ""
    });
    setIsValid({
      ...isValid,
      id: false,
      password: false,
      passwordConfirm: false,
      name: false,
      email: false,
      birth: false,
      isNext: false,
      agree: false
    });
    setValidText({
      id: "",
      password: "",
      passwordConfirm: "",
      name: "",
      email: "",
      birth: "",
      agree: ""
    });
    setOpenSideMenu(false);
    setLoginPopUp(false);
    setSuccessSignUpPopUp(false);
    setHistoryPopUp(false);
    setGetPointPopUp(false);
    setGetPointSuccessPopUp(false);
    setGetPointFailPopUp(false);
    // setPopUpNews(false);
    setRegisterPopUp(false);
    setSnsQrPop(false);
    setSmsPopUp(false);
    setSelectMenu("");
    setEventPopUpData({
      idx: 0,
      is_character: undefined,
      is_sms: undefined,
      pre_event: "N"
    });

    if (registerPopUp === false) {
      document.body.style.overflow = "auto";
    }

    if (popUpNews === false) {
      document.body.style.overflow = "auto";
    }

    if (smsPopUp === false) {
      document.body.style.overflow = "auto";
    }

    if (snsSharePopUp === false) {
      document.body.style.overflow = "auto";
    }
    if (snsQrPop === false) {
      document.body.style.overflow = "auto";
    }
  }, [pathname]);

  useEffect(() => {
    setPopUpNews(false);
  }, [search]);

  // airbridge SDK
  useEffect(() => {
    if (Service_GL) {
      return airbridge.init({
        app: process.env.REACT_APP_SDK_NAME as string,
        webToken: process.env.REACT_APP_SDK_TOKEN as string,
        useStoragePerApp: true,
        shareCookieSubdomain: false,
        utmParsing: true
      });
    }
  }, []);

  useEffect(() => {
    refreshToken();
  }, [pathname]);

  return null;
}

export default ResetInfo;
