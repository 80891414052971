import "./Profile.scss";

import { useRecoilState, useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightToBracket,
  faCaretDown,
  faCaretUp,
  faPlus,
  faUser,
  faCoins,
  faCreditCard,
  faUserClock,
  faHeadset,
  faAddressCard,
  faPowerOff
} from "@fortawesome/free-solid-svg-icons";
import { billingURL, LauncherLogin, Service_GL } from "utils/CommonFunc";
import { t } from "i18next";
import {
  isLoginStateSelector,
  userIdState,
  userNicknameState,
  userProfileKeyState
} from "atoms/userInfoAtom";
import { dropBoxToggleState } from "../recoil/recoil";
import Grade from "./Grade";
import Wallet from "./Wallet";
import { useLogout } from "hooks/useLogout";
import UserProfileImg from "components/common/UserProfileImg";
import { BILLING_CHARGE_URL } from "utils/config/Config";
import { CMservice_code } from "../utils/serviceCode";

const dummy = [
  { nickname: "미미짱", idx: 0 },
  { nickname: "젠지지", idx: 1 },
  { nickname: "가산백호", idx: 2 }
];

function Profile() {
  const isLoginState = useRecoilValue(isLoginStateSelector);
  const userProfileKey = useRecoilValue(userProfileKeyState);
  const userNickname = useRecoilValue(userNicknameState);
  const userId = useRecoilValue(userIdState);
  const [isAccount, setIsAccount] = useState(false);
  const [selectAccount, setSelectAccount] = useState({
    idx: 0,
    nickname: "계정 더보기"
  });
  const [dropBoxToggle, setDropBoxToggle] = useRecoilState(dropBoxToggleState);
  const style = { display: dropBoxToggle.profile ? "inline-block" : "none" };
  const onClickLogout = useLogout();

  const onClickCategory = (idx: number, nickname: string) => {
    setSelectAccount({ idx: idx, nickname: nickname });
    setIsAccount(false);
  };

  return isLoginState.isLogIn ? (
    <div className="box drop" id="profile" style={style}>
      <div
        className="shadow"
        onClick={() =>
          setDropBoxToggle((prev) => ({ ...prev, profile: false }))
        }
      />
      <p className="title">{t("profile")}</p>
      <div className="row-flex">
        <Link
          to={`${process.env.REACT_APP_LOUNGE_DNS}/space/${userProfileKey}`}
        >
          <UserProfileImg />
          <p className="nickname">
            {userNickname === "" ? userId : userNickname}
          </p>
        </Link>
        {Service_GL ? <Grade /> : null}
      </div>
      <Wallet />
      {/* 추후 개발 예정 */}
      {/* <div className="select">
        <button
          onClick={() => setIsAccount((prev) => !prev)}
          className="row-flex"
        >
          <span className="ellipsis">{selectAccount.nickname}</span>{" "}
          <FontAwesomeIcon icon={isAccount ? faCaretUp : faCaretDown} />
        </button>
        {isAccount && (
          <ul>
            {dummy.map((i, idx) => (
              <li
                className={`${
                  idx + 1 === selectAccount.idx ? "on" : ""
                } row-flex`}
                onClick={() => onClickCategory(i.idx, i.nickname)}
              >
                <span>
                  <img src={userImg} className="user-img" />
                  {i.nickname}
                </span>
                <FontAwesomeIcon icon={faArrowRightToBracket} />
              </li>
            ))}

            <li className="plus-account">
              계정 추가하기
              <FontAwesomeIcon icon={faPlus} />
            </li>
          </ul>
        )}
      </div> */}
      <ul className="info-wrap y-scroll">
        <li>
          <p className="sub-title">{t("cate_pay")}</p>
          <ul className="gray-box">
            <li>
              <Link
                to={window.location.href}
                onClick={() =>
                  billingURL(
                    BILLING_CHARGE_URL(CMservice_code, 2),
                    "_self",
                    isLoginState.isLauncher
                  )
                }
              >
                <FontAwesomeIcon icon={faCreditCard} />
                {t("charging_history")}
              </Link>
            </li>
            <li>
              <Link
                to={window.location.href}
                onClick={() =>
                  billingURL(
                    BILLING_CHARGE_URL(CMservice_code, 3),
                    "_self",
                    isLoginState.isLauncher
                  )
                }
              >
                <FontAwesomeIcon icon={faCoins} />
                {t("usage_history")}
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <p className="sub-title">{t("support")}</p>
          <ul className="gray-box">
            {/* <li>
              <Link
                to={`${process.env.REACT_APP_PLATFORM_DNS}/membership/sns_email_auth_confirm`}
              >
                <FontAwesomeIcon icon={faUser} />
                {t("latest_login")}
              </Link>
            </li> */}
            <li>
              <Link
                to={`${process.env.REACT_APP_PLATFORM_DNS}/customer/inquiry_list?service_code=vfun`}
              >
                <FontAwesomeIcon icon={faHeadset} />
                {t("my_inquiries")}
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <p className="sub-title">{t("account")}</p>
          <ul className="gray-box">
            <li>
              <Link
                to={`${process.env.REACT_APP_PLATFORM_DNS}/membership/sns_email_auth_confirm`}
              >
                <FontAwesomeIcon icon={faAddressCard} />
                {t("account_info")}
              </Link>
            </li>
            <li>
              <Link to={window.location.href} onClick={() => onClickLogout()}>
                <FontAwesomeIcon icon={faPowerOff} />
                {t("signout")}
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  ) : (
    <div className="box drop" id="profile" style={style}>
      <div
        className="shadow"
        onClick={() =>
          setDropBoxToggle((prev) => ({ ...prev, profile: false }))
        }
      />
      <p className="title">{t("signin")}</p>
      <ul className="sign-in">
        <li>
          <a
            href={`${
              isLoginState.isLauncher
                ? "javascript:void(0);"
                : process.env.REACT_APP_PLATFORM_DNS
            }/membership/signin?ret=${window.location.href}`}
            className="purple btn"
            onClick={() => LauncherLogin(isLoginState.isLauncher)}
          >
            <FontAwesomeIcon icon={faArrowRightToBracket} />
            {t("signin")}
          </a>
        </li>
        <li>
          <Link
            to={`${isLoginState.isLauncher ? "#" : "/membership/signup"}`}
            className="gray btn"
            onClick={() => LauncherLogin(isLoginState.isLauncher)}
          >
            <FontAwesomeIcon icon={faUser} />
            {t("signup")}
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Profile;
