import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { BILLING_CHARGE_URL, VFUN_IMAGE_DNS } from "utils/config/Config";
import { billingURL } from "utils/CommonFunc";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { getGrade } from "../api/api";
import { headersState, isLauncherState } from "atoms/userInfoAtom";
import { dropBoxToggleState } from "../recoil/recoil";
import { Link } from "react-router-dom";

function Grade() {
  const headers = useRecoilValue(headersState);
  const [cookies, setCookie, removeCookie] = useCookies();
  const service_code = cookies["service_code"];
  const isLauncher = useRecoilValue(isLauncherState);
  const dropBoxToggle = useRecoilValue(dropBoxToggleState);

  const { isLoading, data } = useQuery(["grade"], () => getGrade(headers), {
    enabled: !!dropBoxToggle.profile
  });

  return !isLoading ? (
    data?.result === 1 ? (
      <Link
        to={window.location.href}
        onClick={() =>
          billingURL(
            BILLING_CHARGE_URL(service_code || "vfun", 1),
            "_self",
            isLauncher
          )
        }
      >
        <img
          src={`${VFUN_IMAGE_DNS}/web/vfun/images/common/grade/bg-${
            data.data.split(".")[0]
          }.png`}
          alt="grade"
        />
      </Link>
    ) : null
  ) : (
    <FontAwesomeIcon icon={faSpinner} pulse style={{ marginLeft: "1rem" }} />
  );
}

export default Grade;
