import { ILauncherData, launcherStartApi } from "api/launcherApi";
import { gameStatusState } from "atoms/uiAtom";
import { headersState, headersType, isLauncherState } from "atoms/userInfoAtom";
import axios from "axios";
import { t } from "i18next";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

const LauncherData = async (service_code: string, headers: headersType) => {
  const failureTimeout = setTimeout(() => {
    // 실패시 코드
    alert(t("need_install_launcher"));
    window.location.replace(
      `${process.env.REACT_APP_PLATFORM_DNS}/game/download`
    );
  }, 1000);

  window.addEventListener("blur", () => {
    clearTimeout(failureTimeout);
  });

  const link = document.createElement("a");

  try {
    let res = await axios.post(
      launcherStartApi,
      { service_code: service_code },
      {
        headers: headers
      }
    );
    const data: ILauncherData = res.data.data;
    if (res.data.result === 1) {
      link.href = data.game_run_url;
    } else if (res.data.result === -97) {
      link.href = data.protocol;
    } else {
      console.log("왕실패");
    }
  } catch (error) {
    console.log("launcher", error);
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const usePlayGame = () => {
  const isLauncher = useRecoilValue(isLauncherState);
  const gameStatus = useRecoilValue(gameStatusState);
  const headers = useRecoilValue(headersState);
  const [searchParams] = useSearchParams();
  const service_code = searchParams.get("service_code");

  const playType = () => {
    if (isLauncher) {
      if (gameStatus === "install") {
        window.app.vFunGameInstall(service_code);
      } else if (gameStatus === "patch") {
        window.app.vFunGamePatch(service_code);
      } else if (gameStatus === "execute") {
        window.app.vFunGameExecute(service_code);
      }
    } else {
      LauncherData(service_code as string, headers);
    }
  };

  return playType;
};
